import { makeStyles } from 'tss-react/mui'
import { SidebarColors } from '@/constants'

interface Props {
  openSidebar?: boolean
  contentBackground?: string
}

export const useContentStyles = makeStyles<Props>()((
  theme,
  { openSidebar, contentBackground },
) => {
  const background =
    contentBackground || SidebarColors(theme).contentBackgroundColor

  return {
    sidebarContent: {
      background,
      borderTopLeftRadius: openSidebar ? 16 : 0,
      borderTopRightRadius: openSidebar ? 16 : 0,
      marginTop: openSidebar ? -20 : 0,
      minHeight: 320,
      zIndex: 1,
      overflow: openSidebar ? 'initial' : 'auto',

      [theme.breakpoints.down('sm')]: {
        background: openSidebar ? background : 'transparent',
        position: openSidebar ? 'relative' : 'absolute',
        top: openSidebar ? 0 : 10,
        left: openSidebar ? 0 : 165,
        minHeight: openSidebar ? 320 : 'auto',
        width: openSidebar ? 'auto' : 'calc(100% - 185px)',
      },
    },
  }
})
