import { makeStyles } from 'tss-react/mui'
import { darken, lighten } from '@mui/material'
import { OrientationComponentsEnum, VariantsComponentsEnum } from '@/enums'

interface Props {
  variant?: VariantsComponentsEnum
  orientation?: OrientationComponentsEnum
}

export const useMissionItemStyles = makeStyles<Props>()((
  theme,
  { variant, orientation },
) => {
  const getSizesByVariantAndOrientation = (
    variantType: VariantsComponentsEnum,
  ) => {
    if (
      variantType === VariantsComponentsEnum.LARGE &&
      orientation === OrientationComponentsEnum.VERTICAL
    ) {
      return {
        width: 200,
        height: 150,
      }
    }

    if (orientation === OrientationComponentsEnum.HORIZONTAL) {
      return {
        width: '100%',
        height: 76,
      }
    }

    if (
      variantType === VariantsComponentsEnum.SMALL &&
      orientation === OrientationComponentsEnum.VERTICAL
    ) {
      return {
        width: 130,
        height: 102,
      }
    }
  }

  const getPaddingByVariantAndOrientation = () => {
    if (orientation === OrientationComponentsEnum.HORIZONTAL) {
      return 0
    }

    if (variant === VariantsComponentsEnum.LARGE) {
      return 16
    }

    return 8
  }

  return {
    missionStandardWrapper: {
      borderRadius: 8,
      background: theme.palette.background.default,
      boxShadow: `0 4px 20px ${
        theme.palette.mode === 'dark'
          ? lighten(theme.palette.background.paper, 0.1)
          : darken(theme.palette.background.paper, 0.2)
      }`,
      cursor: 'pointer',
      padding: getPaddingByVariantAndOrientation(),
      ...getSizesByVariantAndOrientation(variant),

      [theme.breakpoints.down('sm')]: {
        padding: orientation === OrientationComponentsEnum.HORIZONTAL ? 0 : 8,
        ...getSizesByVariantAndOrientation(VariantsComponentsEnum.SMALL),
      },
    },
  }
})
