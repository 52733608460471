import { t } from 'i18next'
import parse from 'html-react-parser'
import { Box, Grid, Typography, useTheme } from '@mui/material'

import {
  MissionBundleItemComponent,
  MissionBundleProgressComponent,
} from '@/components/common'
import { MissionCommonModels } from '@/state'
import { VariantsComponentsEnum } from '@/enums'

import { useBreakpoints } from '@/hooks'

type Props = {
  missionBundle: MissionCommonModels.MissionHistoryBundleDetailsType
  variant?: VariantsComponentsEnum
  showEndedMissions?: boolean
  isMissionWidget?: boolean
  selectedBundleDescription: string
  selectedBundleName: string
}

export const MissionBundleDetailComponent = ({
  missionBundle,
  variant = VariantsComponentsEnum.LARGE,
  showEndedMissions = false,
  isMissionWidget,
  selectedBundleName,
  selectedBundleDescription,
}: Props) => {
  const theme = useTheme()

  const { isMobile, isTablet } = useBreakpoints()

  if (!isMissionWidget || isMobile || isTablet) {
    return (
      <MissionBundleItemComponent
        missionBundle={missionBundle}
        variant={variant}
        showEndedMissions={showEndedMissions}
        borderRadius={16}
        noBorderBottom
      />
    )
  }

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item md={4} width="100%">
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          boxShadow={theme.shadows[2]}
          bgcolor={theme.palette.background.default}
          borderRadius={4}
          padding={3}
        >
          <Typography fontSize={24} fontWeight={700}>
            {selectedBundleName}
          </Typography>
          <Typography>{parse(selectedBundleDescription)}</Typography>
          <MissionBundleProgressComponent
            missionBundle={missionBundle}
            showEndedMissions={showEndedMissions}
            variant={variant}
            label={`${t('progress')}:`}
          />
        </Box>
      </Grid>

      <Grid item md={8} width="100%">
        <MissionBundleItemComponent
          missionBundle={missionBundle}
          variant={VariantsComponentsEnum.LARGE}
          showEndedMissions={showEndedMissions}
          borderRadius={16}
          customHeight={isMobile ? 177 : 350}
          isMissionsPage
        />
      </Grid>
    </Grid>
  )
}
