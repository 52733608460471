import { ScopedCssBaseline } from '@mui/material'
import './init'
import {
  ActiveBoosterComponent,
  AvatarComponent,
  MechanicsStatusComponent,
  PointsComponent,
  ShopLayoutComponent,
  TokensProfileAnimationComponent,
  MyBoostersComponent,
  SidebarComponent,
  BadgeLevelComponent,
  // MissionsComponent
} from './components'

import { WidgetTypeEnum } from './enums'
import { store } from './state'

type Props = {
  Div: HTMLElement
  /* 
  TODO: Maybe in the future we will use this prop
  callbacks?: {
    rewardShop?: {
      next_btn?: (props: any) => void
    }
  } */
}

const getScopedCssStyles = (type: WidgetTypeEnum) => {
  const isErrorToken = store.getState().initConfig.isErrorToken
  const sx = {
    backgroundColor: 'transparent',
    opacity: isErrorToken ? 0.3 : 1,
    pointerEvents: isErrorToken ? 'none' : 'initial',
  }

  switch (type) {
    case WidgetTypeEnum.SIDEBAR:
      return {
        ...sx,
        height: '100%',
      }
    default:
      return sx
  }
}

export function App({ Div }: Props) {
  const type = Div.getAttribute('data-type') as WidgetTypeEnum

  if (
    !Div.hasChildNodes() &&
    Object.values(WidgetTypeEnum).includes(type as WidgetTypeEnum)
  ) {
    return (
      <ScopedCssBaseline enableColorScheme sx={getScopedCssStyles(type)}>
        {type === WidgetTypeEnum.AVATAR && <AvatarComponent Div={Div} />}
        {type === WidgetTypeEnum.BADGE_LEVEL && (
          <BadgeLevelComponent Div={Div} />
        )}
        {type === WidgetTypeEnum.POINTS && <PointsComponent />}
        {type === WidgetTypeEnum.REWARD_SHOP && (
          <ShopLayoutComponent Div={Div} />
        )}
        {type === WidgetTypeEnum.TOKENS && (
          <TokensProfileAnimationComponent Div={Div} />
        )}
        {type === WidgetTypeEnum.ACTIVE_BOOSTERS && (
          <ActiveBoosterComponent Div={Div} />
        )}
        {type === WidgetTypeEnum.GAMIFICATION_MECHANICS && (
          <MechanicsStatusComponent Div={Div} />
        )}
        {type === WidgetTypeEnum.MY_BOOSTERS && (
          <MyBoostersComponent isStandalone />
        )}
        {type === WidgetTypeEnum.SIDEBAR && <SidebarComponent Div={Div} />}
        {/* TODO: uncomment when ready */}
        {/* {type === WidgetTypeEnum.MISSIONS && <MissionsComponent />} */}
      </ScopedCssBaseline>
    )
  }
}
