import { useTranslation } from 'react-i18next'
import { Badge, Box, Button, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'

import { ReactComponent as IconFlash } from '@/assets/icons/common/icon-flash.svg'
import { ChestByTrigger } from '@/constants'
import { useBreakpoints } from '@/hooks'

import { RewardTriggerTypeEnum } from '@/enums'
import { SliderItemsComponent } from '@/components/common'
import { useStyles } from './style'

import { useMyRewards } from '../hooks'

type Props = {
  closedVersion: boolean
}

export function MyRewardsComponent({ closedVersion }: Props) {
  const { t } = useTranslation()

  const {
    myRewardsByTrigger,
    baseUrl,
    boostersEnable,
    gamificationOpt,
    claimRewardsAction,
    handleShowDialogShop,
  } = useMyRewards()

  const { isMobile, isXsMobile } = useBreakpoints()

  const { classes } = useStyles({ closedVersion })

  let rewardsType = Object.keys(myRewardsByTrigger).filter(
    (key) => key !== RewardTriggerTypeEnum.EASTER_EGGS,
  )

  if (closedVersion) {
    rewardsType = isXsMobile ? rewardsType.slice(0, 1) : rewardsType.slice(0, 2)
  }

  let hasChests = false
  rewardsType.forEach((triggerKey) => {
    if (Object.keys(myRewardsByTrigger[triggerKey]).length > 0) {
      hasChests = true
    }
  })

  const showAddBooster =
    boostersEnable && (!hasChests || !isMobile || (isMobile && !closedVersion))

  return (
    <>
      {gamificationOpt.enable && (
        <SliderItemsComponent
          amountItems={rewardsType.length + 1}
          itemsByBlock={4}
          expanded={!closedVersion}
          content={
            <Box
              className={classes.myRewards}
              width={
                rewardsType.length + 1 <= 4 && !closedVersion ? '100%' : 'auto'
              }
            >
              {showAddBooster && (
                <Box
                  className="myRewardItem"
                  sx={{ order: rewardsType.length }}
                >
                  <Button onClick={handleShowDialogShop} className="addBooster">
                    <IconFlash />
                    <Box className="addIcon">
                      <Add />
                    </Box>
                  </Button>
                  <Typography variant="body2">{t('boosters')}</Typography>
                </Box>
              )}
              {rewardsType.map((triggerKey, index) => {
                const amountGroups = Object.keys(
                  myRewardsByTrigger[triggerKey],
                ).length
                if (amountGroups > 0) {
                  return (
                    <Badge badgeContent={amountGroups} key={triggerKey}>
                      <Box
                        className="myRewardItem"
                        onClick={() => claimRewardsAction(triggerKey)}
                        order={rewardsType.length - (index + 1)}
                      >
                        <img
                          src={`${baseUrl}${ChestByTrigger[triggerKey].icon}`}
                          alt="Chest Closed"
                          className="chestIcon"
                        />
                        <Typography variant="body2">
                          {t(ChestByTrigger[triggerKey].label)}
                        </Typography>
                      </Box>
                    </Badge>
                  )
                } else {
                  return ''
                }
              })}
            </Box>
          }
        />
      )}
    </>
  )
}
