import { Service } from 'typedi'
import { loginThunk, store } from '@/state'
import SocketSubscriptionService from './socket.service'

import { decodeJWT } from './jwt.util'

export type ApiMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

export const objectToQueryString = (obj: any) =>
  Object.keys(obj)
    .filter((key) => obj[key] !== '')
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')

/**
 * @description Handle API REST calls
 * @class Rest
 */

export type ApiResult = 'success' | 'failure'

type ResponseType = 'blob' | 'json'

export interface RequestParams {
  method: ApiMethod
  endpoint: string
  headers?: Object
  body?: Object
  queries?: Object
  responseType?: ResponseType
}

@Service()
export class Rest {
  /**
   *
   * @description Method to perform REST requests
   * @memberof Rest
   */

  private isRefreshing = false
  private refreshTokenPromise: Promise<string> | null = null
  private count401Attempts = 0

  private refreshToken = async (): Promise<string> => {
    if (!this.isRefreshing) {
      this.isRefreshing = true

      this.refreshTokenPromise = store
        .dispatch(
          loginThunk({
            clientId: store.getState().initConfig.clientId,
            identityToken: store.getState().initConfig.playerIdentityToken,
            serviceUrl: store
              .getState()
              .initConfig.serviceUrl.replace(/\/+$/, ''),
          }),
        )
        .then((resultAction) => {
          let newToken = ''
          if (
            loginThunk.fulfilled.match(resultAction) &&
            resultAction.payload.accessToken
          ) {
            newToken = resultAction.payload.accessToken
          }
          this.isRefreshing = false
          this.refreshTokenPromise = null
          return newToken
        })
        .catch((error) => {
          this.isRefreshing = false
          this.refreshTokenPromise = null
          throw error
        })
    }

    return this.refreshTokenPromise!
  }

  private getToken = async (): Promise<string> => {
    let token = JSON.parse(localStorage.getItem('gamanzaengage_token'))

    if (hasTokenExpired() || !token) {
      token = await this.refreshToken()
    }

    return token
  }

  sendData = async <D>(params: {
    url?: string
    method: ApiMethod
    endpoint: string
    body?: unknown
    queries?: any
    isLogin?: boolean
  }): Promise<D> => {
    const { body, method, endpoint, queries, isLogin } = params
    const url = this.getURL({ url: params.url, endpoint, queries })
    const token = isLogin ? '' : await this.getToken()

    try {
      const req = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method,
      }
      if (body) req['body'] = JSON.stringify(body)
      const response = await fetch(url, req)

      const text = await response.text()

      if (text === '') {
        return { statusCode: response.status } as any
      }

      const data = JSON.parse(text)

      while (this.count401Attempts < 3) {
        if (
          response.status.toString() === '401' ||
          (isLogin && response.status.toString() !== '201')
        ) {
          if (!isLogin) {
            await this.refreshToken()
          }
          this.count401Attempts++
          return this.sendData(params)
        } else {
          this.count401Attempts = 0
        }
        return { ...data, statusCode: response.status }
      }

      return { ...data, statusCode: response.status }
    } catch (error) {
      return error as any
    }
  }

  /**
   *
   * @description Build the url to fetch
   * @memberof Rest
   */
  private getURL = (params?: {
    endpoint: string
    url?: string
    queries?: string
  }) => {
    const { endpoint, queries } = params

    if (!params?.url && !localStorage.getItem('gamanzaengage_serviceUrl')) {
      throw new Error('Service URL is not defined in local storage')
    }

    let queryParams

    if (queries) queryParams = objectToQueryString(queries)

    const serverUrl =
      params?.url ||
      JSON.parse(localStorage.getItem('gamanzaengage_serviceUrl'))
    const url$ = queries
      ? `${serverUrl}${endpoint}?${queryParams}`
      : `${serverUrl}${endpoint}`

    return url$
  }
}

/**
 * @function wsSubscriptionFactory
 * Singleton Factory function to create an only one instance of the Socket Service
 */
export const wsSubscriptionFactory = function (namespace?: string) {
  let segmentSubscription: SocketSubscriptionService | undefined

  return {
    getInstance: () => {
      if (!segmentSubscription) {
        segmentSubscription = new SocketSubscriptionService({
          namespace,
        })
      }
      return segmentSubscription
    },
    destroy: () => {
      if (segmentSubscription) {
        segmentSubscription?.disconnect()
        segmentSubscription = undefined
      }
    },
  }
}

export const hasTokenExpired = () => {
  const token = JSON.parse(localStorage.getItem('gamanzaengage_token'))

  const { decoded, isValid } = decodeJWT(token)

  if (isValid) {
    return decoded.exp < Math.floor(Date.now() / 1000)
  } else {
    return false
  }
}
