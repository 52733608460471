import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import parse from 'html-react-parser'
import { RewardShopTypeEnum } from '@gamanza-engage/mf-enums'
import { CheckBox } from '@mui/icons-material'

import {
  Box,
  Button,
  Checkbox,
  Collapse,
  colors,
  FormControlLabel,
  Typography,
  useTheme,
} from '@mui/material'
import { RewardShopModels, RootState } from '@/state'
import { IconTypeEnum } from '@/enums'

import {
  CounterInputComponent,
  IconComponent,
  TooltipComponent,
} from '@/components/common'
import { ReactComponent as BoosterIcon } from '@/assets/boosterFloatingAction.svg'
import { getFormatNumber } from '@/utils'

import { useBreakpoints } from '@/hooks'
import { ProductFormAddressComponent } from './product-form-address.component'
import { useStyles } from '../style'

type Props = {
  productForm: RewardShopModels.ProductFormType
  product: RewardShopModels.ProductType
  actionButtons: React.ReactNode
  editLocation: boolean
  handleOnChange: (field: string, newValue: string | number | boolean) => void
  getDisableMessage: () => React.ReactNode
  handleEditLocation: () => void
}

export function ProductFormStep1Component({
  productForm,
  product,
  actionButtons,
  editLocation,
  handleOnChange,
  getDisableMessage,
  handleEditLocation,
}: Props) {
  const { t } = useTranslation()

  const { client, playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { gamificationPlayer } = useSelector(({ player }: RootState) => player)

  const theme = useTheme()

  const { classes } = useStyles({ client })

  const translation =
    product?.translations?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    ) || product?.translations?.[0]

  const [isExpanded, setIsExpanded] = useState(false)

  const description = translation?.description || product?.description

  const shouldShowToggle = description && description.length > 200

  const { isMobile, isTablet } = useBreakpoints()

  const handleToggle = () => {
    setIsExpanded(!isExpanded)
  }

  const getStockMessage = () => {
    if (product?.remainingItems === 0) {
      return {
        color: colors.red[700],
        message: t('out_of_stock'),
      }
    }

    if (product?.remainingItems <= 10) {
      return {
        color: colors.orange[700],
        message: t('lowStock', { remainingItems: product?.remainingItems }),
      }
    }

    return {
      color: colors.green[500],
      message: t('inStock'),
    }
  }

  const getActivateBooster = () => {
    return (
      <Box display="flex" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              name="activateBooster"
              checked={productForm.activateBooster}
              onChange={(e) =>
                handleOnChange('activateBooster', e.target.checked)
              }
              checkedIcon={<CheckBox />}
            />
          }
          label={t('activateBooster')}
        />
        <TooltipComponent
          title={t('activateBooster')}
          description={t('activeBoosterDescription')}
          icon={
            <Box display="flex" justifyContent="center">
              <BoosterIcon width={30} height={30} />
            </Box>
          }
          maxWidth={340}
        />
      </Box>
    )
  }

  return (
    <Box>
      <Typography className={classes.title}>
        {translation?.name || product?.name}
      </Typography>

      {product?.reward?.type === RewardShopTypeEnum.EXTERNAL && (
        <Typography
          gutterBottom
          variant="body2"
          fontWeight={700}
          color={getStockMessage().color}
        >
          {getStockMessage().message}
        </Typography>
      )}

      {shouldShowToggle ? (
        <Collapse
          className={classes.description}
          in={isExpanded}
          collapsedSize={100}
          sx={{
            '&::after': {
              background:
                shouldShowToggle &&
                `linear-gradient(to bottom, transparent, ${theme.palette.background.paper})`,
              display: !isExpanded ? 'block' : 'none',
            },
          }}
        >
          <Typography component="div" variant="body2">
            {description ? parse(description) : ''}
          </Typography>
        </Collapse>
      ) : (
        <Typography component="div" variant="body2">
          {description ? parse(description) : ''}
        </Typography>
      )}

      {shouldShowToggle && (
        <Button
          variant="text"
          onClick={handleToggle}
          sx={{
            color: theme.palette.text.secondary,
            textTransform: 'none',
          }}
        >
          {isExpanded ? t('readLess') : t('readMore')}
        </Button>
      )}

      {(isMobile || isTablet) &&
        product?.reward?.type === RewardShopTypeEnum.BOOSTER && (
          <Box ml={client === 'JP' ? 0 : 2} mt={2}>
            {getActivateBooster()}
          </Box>
        )}

      <Box
        display="flex"
        flexDirection={{ sm: 'column', md: 'column-reverse' }}
        justifyContent={{ xs: 'space-between' }}
        gap={2.5}
        mt={2}
      >
        <CounterInputComponent
          value={productForm.quantity}
          fieldName="quantity"
          handleOnChange={handleOnChange}
          disableControls={client === 'JP'}
          label={client === 'JP' && t('qty')}
        />

        {!(isMobile || isTablet) &&
          product?.reward?.type === RewardShopTypeEnum.BOOSTER &&
          getActivateBooster()}

        <Box className={classes.tokensBoxContainer}>
          <Box className={classes.tokensBoxWrapper}>
            <Box className={classes.tokensBox}>
              <IconComponent type={IconTypeEnum.TOKEN} size={30} />
              <Typography variant="body2">
                {getFormatNumber(
                  productForm.quantity *
                    product?.reward?.price?.virtualCurrencyPrice,
                  playerLocale,
                )}
              </Typography>
            </Box>
            <Typography
              variant="caption"
              className={classes.remainingTokens}
            >{`${t('remainingTokens')} ${getFormatNumber(
              gamificationPlayer.tokens -
                productForm.quantity *
                  product?.reward?.price?.virtualCurrencyPrice,
              playerLocale,
            )}`}</Typography>
          </Box>
        </Box>
      </Box>

      {client === 'JP' &&
        product?.reward?.type === RewardShopTypeEnum.EXTERNAL && (
          <Box mt={2}>
            <ProductFormAddressComponent
              product={product}
              productForm={productForm}
              handleOnChange={handleOnChange}
            />
          </Box>
        )}

      <Box width="100%" mt={2}>
        {actionButtons}

        {getDisableMessage()}
      </Box>
    </Box>
  )
}
